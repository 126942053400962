<template>
  <div class="host-info dfc fs24">
    <div class="host-content h100">
      <!-- 时间 -->
      <div class="info-center">
        <div v-for="(item, index) in timeData" :key="index">
          <everyday-time-info
            @selectInfo="selectInfo"
            :listIndex="index"
            :listSelectIndex="currentListSelectIndex"
            :activeIndex="currentSelectIndex"
            :info="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      timeData: [],
      currentDate: [],
      currentSelectIndex: NaN,
      currentListSelectIndex: NaN
    };
  },
  async created() {
    this.$title("我的可用日程档");
    await this.dateMeeting();
    this.getDay();
  },
  methods: {
    // 获取 维护自己档期日期没有的前端三个都关闭  0灰色关闭 1白色开放 2黄色有会议
    async dateMeeting() {
      const resp = await this.$API.get("myDateMeeting").then(resp => resp);
      if (resp.code == 1000) {
        this.currentDate = resp.data;
      }
    },
    selectInfo(data) {
      this.currentSelectIndex = data.activeIndex;
      this.currentListSelectIndex = data.listSelectIndex;
    },
    getDay() {
      const data = [];
      // 最多显示30天
      for (let i = 0; i <= 30; i++) {
        const _data = {
          date: dayjs()
            .add(i, "day")
            .format("YYYY-MM-DD"),
          time: [0, 0, 0],
          active: false
        };
        data.push(_data);
      }

      data.forEach(item => {
        for (let _key in this.currentDate) {
          if (_key == item.date) {
            item.time = this.currentDate[_key];
          }
        }
      });

      this.timeData = data;
    }
  }
};
</script>

<style lang="less" scoped>
.host-info {
  .info-center {
    margin: 0.36rem 0.36rem 0 0.36rem;
    overflow-y: auto;
  }
}
</style>
